'use strict';

angular.module('unitoLandingApp')
.controller('NewEsterniController',
		['$scope', '$stateParams','$state', '$uibModalInstance', 'entity', 'tipologieEsterniList','durataList','struttureList','Esterni','$q', 
	        function($scope, $stateParams,$state, $uibModalInstance, entity, tipologieEsterniList, durataList,struttureList,Esterni,$q) {

    	var ctrl = this;
    	
    	$scope.stateDocente = $state.current.name.indexOf('docente')>-1 ? true: false;
    	
    	$scope.checkIfDateIsBeforeToday = checkIfDateIsBeforeToday;
        
        $scope.dateFormat = "dd/MM/yyyy";
    	
    	function checkIfDateIsBeforeToday(dateToVerify){
    		var isValid = false;
        	var deferred = $q.defer();
        	var today = new Date();
        	today.setHours(0,0,0,0);
    		if(dateToVerify>=today)
    			deferred.resolve(isValid);
    		else
    			deferred.reject(false);
    			
    		
    		return deferred.promise;
    	};


    	//Tipologie Esterni
    	$scope.tipologieEsterniList = tipologieEsterniList;
    	//Durata
    	$scope.durataList = durataList;
    	//Strutture
    	$scope.struttureList = struttureList;
    	$scope.bloccaPrimoLivello = false;
    	$scope.bloccaSecondoLivello = false;
    	$scope.bloccaTerzoLivello = false;
    	
    	//Fix formato date
    	if(entity.dataInizio!=null)
    		entity.dataInizio = new Date(entity.dataInizio);
    	else
    		entity.dataInizio = new Date();
    	
    	//Gestione strutture
    	if($scope.struttureList != null && $scope.stateDocente){
    		entity.strutturaTerzoLivello = $scope.struttureList.filter(function( obj ) {
    			return obj.livello===3;
			})[0];
    		entity.strutturaSecondoLivello = $scope.struttureList.filter(function( obj ) {
    			return obj.livello===2;
			})[0];
    		entity.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
    			return obj.livello===1;
			})[0];
    		
    		
//    		//Terzo livello
//    		var struttureTerzoLivello = $scope.struttureList.filter(function( obj ) {
//    			return obj.livello===3;
//			});
//    		//Se c'è solo un 3° livello --> tutto bloccato
//    		if(struttureTerzoLivello.length===1){
//    	    	entity.strutturaTerzoLivello = struttureTerzoLivello[0];
//    	    	entity.strutturaSecondoLivello = $scope.struttureList.filter(function( obj ) {
//        			return (obj.idAb === entity.strutturaTerzoLivello.idAbPadre && obj.livello===2);
//    			})[0];
//    	    	//Il terzo livello è collegato direttamente ad un primo
//    	    	if(entity.strutturaSecondoLivello==null){
//    	    		entity.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
//            			return (obj.idAb === entity.strutturaTerzoLivello.idAbPadre && obj.livello===1);
//        			})[0];
//    	    	}
//    	    	else{
//	    	    	entity.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
//	        			return (obj.idAb === entity.strutturaSecondoLivello.idAbPadre && obj.livello===1);
//	    			})[0];
//    	    	}
//    			
//    	    	$scope.bloccaPrimoLivello = true;
//    	    	$scope.bloccaSecondoLivello = true;
//    		}
//    		else{
//    			//Secondo livello
//        		var struttureSecondoLivello = $scope.struttureList.filter(function( obj ) {
//        			return obj.livello===2;
//    			});
//        		//Se c'è solo un 2° livello --> il primo è bloccato
//        		if(struttureSecondoLivello.length===1){
//        			entity.strutturaSecondoLivello = struttureSecondoLivello[0];
//        			entity.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
//            			return obj.idAb === entity.strutturaSecondoLivello.idAbPadre;
//        			})[0];
//        			
//        			$scope.bloccaPrimoLivello = true;
//        		}
//        		else{
//        			//Primo livello
//            		var strutturePrimoLivello = $scope.struttureList.filter(function( obj ) {
//            			return obj.livello===1;
//        			});
//            		if(strutturePrimoLivello.length===1){
//            			entity.strutturaPrimoLivello = strutturePrimoLivello[0];
//            			$scope.bloccaPrimoLivello = true;
//            		}
//        		}
//    		}
    		
    		
    		
    		
//    		//Primo livello
//    		var strutturePrimoLivello = $scope.struttureList.filter(function( obj ) {
//    			return obj.livello===1;
//			});
//    		if(strutturePrimoLivello.length===1){
//    			entity.strutturaPrimoLivello = strutturePrimoLivello[0];
//    			$scope.bloccaPrimoLivello = true;
//    			
//    			//Secondo livello
//        		var struttureSecondoLivello = $scope.struttureList.filter(function( obj ) {
//        			return obj.livello===2;
//    			});
//        		if(struttureSecondoLivello.length===1){
//        			entity.strutturaSecondoLivello = struttureSecondoLivello[0];
//        			$scope.bloccaSecondoLivello = true;
//        			
//        			//Terzo livello
//            		var struttureTerzoLivello = $scope.struttureList.filter(function( obj ) {
//            			return obj.livello===3;
//        			});
//            		if(struttureTerzoLivello.length===1){
//            			entity.strutturaTerzoLivello = struttureTerzoLivello[0];
//            			$scope.bloccaTerzoLivello = true;
//            		}	
//        		}	
//    		}
    		
    	}
    		
//    		if(entity.strutturaAfferenza.livello===1){
//    			$scope.strutturaPrimoLivello = entity.strutturaAfferenza;
//    		}
//    		//Secondo livello
//    		else if(entity.strutturaAfferenza.livello===2){
//    			$scope.strutturaSecondoLivello = entity.strutturaAfferenza;
//    			//Setto il primo
//    			$scope.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
//				  return obj.idAb===$scope.strutturaSecondoLivello.idAbPadre;
//				})[0];
//    		}
//    		//Terzo livello
//    		else{
//    			
//    			//Recupero il padre
//    			var result = $scope.struttureList.filter(function( obj ) {
//				  return obj.idAb===entity.strutturaAfferenza.idAbPadre;
//				})[0];
//    			if(result.livello===1){
//    				$scope.strutturaSecondoLivello = entity.strutturaAfferenza;
//    				$scope.strutturaPrimoLivello = result;
//    			}
//    			else{
//    				$scope.strutturaTerzoLivello = entity.strutturaAfferenza;
//    				$scope.strutturaSecondoLivello = result;
//    				//Setto il primo
//        			$scope.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
//      				  return obj.idAb===$scope.strutturaSecondoLivello.idAbPadre;
//      				})[0];
//    			}
//    				
//    			
//
//
//    		}
//    	}
    	
    	$scope.esterni = entity;
    	
    	$scope.today = new Date();
    	$scope.today.setDate($scope.today.getDate() - 1);
    	

        var onSaveFinished = function (result) {
            $scope.$emit('unitoLandingApp:esterniUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function (formIsValid) {
        	if(formIsValid){
        		if($scope.esterni.fonte=='ESTERNO'){
	        		//Gestione strutture
	            	if($scope.esterni.strutturaTerzoLivello!=null)
	            		$scope.esterni.struttura = $scope.esterni.strutturaTerzoLivello;
	            	else if($scope.esterni.strutturaSecondoLivello!=null)
	            		$scope.esterni.struttura = $scope.esterni.strutturaSecondoLivello;
	            	else
	            		$scope.esterni.struttura = $scope.esterni.strutturaPrimoLivello;
	            	

	            	var nuovoEsterno = angular.copy($scope.esterni);
	            	
	            	delete nuovoEsterno.strutturaPrimoLivello;
	            	delete nuovoEsterno.strutturaSecondoLivello;
	            	delete nuovoEsterno.strutturaTerzoLivello;
	            	
	            	
	            	Esterni.newEsterniRequest(nuovoEsterno, onSaveFinished);
	                
        		}
        	}
        	
        	
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        
        $scope.startsWith = function(comune, viewValue) {
        	return comune.substring(0, viewValue.length).toLowerCase() == viewValue.toLowerCase();
        } 
        
        function transformDate(date) {
        	var formatted = pad(date.getDate(), 2) + "/" + pad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
        	return formatted;
        }
        
        function pad(n, width, z) {
        	z = z || '0';
        	n = n + '';
        	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        
        ctrl.preventKeyboard = function($event) {
    		$event.preventDefault();
    	}

        
        $scope.almenoUnaStruttura = function(){
        	if($scope.strutturaPrimoLivello==null&&$scope.strutturaSecondoLivello==null&&$scope.strutturaTerzoLivello==null)
        		return true;
        	
        	return false;
        };
   

        
      
}]);
