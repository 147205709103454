(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Provider_trDetailController', Provider_trDetailController);

    Provider_trDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Provider_tr', 'Provider'];

    function Provider_trDetailController($scope, $rootScope, $stateParams, previousState, entity, Provider_tr, Provider) {
        var vm = this;

        vm.provider_tr = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('unitoLandingApp:provider_trUpdate', function(event, result) {
            vm.provider_tr = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
