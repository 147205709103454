(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('esterni', {
                parent: 'entity',
                url: '/esternis?page&sort&search',
                data: {
                    authorities: ['ROLE_GESTIONE_ESTERNI','ROLE_CREAZIONE_ESTERNI'],
                    pageTitle: 'unitoLandingApp.esterni.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/esterni/esternis.html',
                        controller: 'EsterniController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'cognome,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                	pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('esterni');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('esterni.detail', {
                parent: 'entity',
                url: '/esterni/{id}',
                data: {
                    authorities: ['ROLE_GESTIONE_ESTERNI'],
                    pageTitle: 'unitoLandingApp.esterni.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/esterni/esterni-detail.html',
                        controller: 'EsterniDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('esterni');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Esterni', function($stateParams, Esterni) {
                        return Esterni.get({id : $stateParams.id});
                    }],
                    'struttureList': ['StruttureService', function(StruttureService) {
                        return StruttureService.external.getFromUser({}).$promise;
                    }]
                }
            })
            .state('esterni.new', {
                parent: 'esterni',
                url: '/new',
                data: {
                    authorities: ['ROLE_GESTIONE_ESTERNI'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                	$uibModal.open({
                        templateUrl: 'app/entities/esterni/esterni-dialog.html',
                        controller: 'EsterniDialogController',
                        controllerAs: 'esterniCtrl',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                	id: null,
                                	dataCreazione: null,
                                	dataInizio: null,
                                	dataFine: null,
                                	dataEliminazione: null,
                                	dataValiditaDocumento: null,
                                	codiceFiscale: null,
                                	documentoIdendita: null,
                                	nazionalita: null,
                                	nome: null,
                                	cognome: null,
                                	genere: null,
                                	luogoNascita: null,
                                	emailPrivata: null,
                                	mobilePrivato: null,
                                	flAttivo: 0,
                                	dataNascita: null,
                                	durata: null,
                                	registratoSpid: 0,
                                	strutturaAfferenza: null,
                                	tipoDocumentoIdentita: null,
                                	tipologiaUtente: null,
                                	categoria: 0,
                                	fonte: 'ESTERNO'
                                };
                            },
                            'tipologieEsterniList': ['TipologieEsterniService', function(TipologieEsterniService) {
                                return TipologieEsterniService.external.get({}).$promise;
                            }],
                            'tipiDocIdentitaList': ['TipiDocIdentitaService', function(TipiDocIdentitaService) {
                                return TipiDocIdentitaService.external.get({}).$promise;
                            }],
                            'durataList': ['EsterniDurataService', function(EsterniDurataService) {
                                return EsterniDurataService.external.get({}).$promise;
                            }],
                            'struttureList': ['StruttureService', function(StruttureService) {
                                return StruttureService.external.getFromUser({}).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                    	$state.go('esterni', null, { reload: true });
                    }, function() {
                        $state.go('esterni');
                    })
                }]
            })
            .state('esterni.new-request', {
                parent: 'esterni',
                url: '/new-request',
                data: {
                    authorities: ['ROLE_GESTIONE_ESTERNI'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                	$uibModal.open({
                        templateUrl: 'app/entities/esterni/new-esterni-dialog.html',
                        controller: 'NewEsterniController',
                        controllerAs: 'esterniCtrl',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                	id: null,
                                	dataCreazione: null,
                                	dataInizio: null,
                                	dataFine: null,
                                	dataEliminazione: null,
                                	dataValiditaDocumento: null,
                                	codiceFiscale: null,
                                	documentoIdendita: null,
                                	nazionalita: null,
                                	nome: null,
                                	cognome: null,
                                	genere: null,
                                	luogoNascita: null,
                                	emailPrivata: null,
                                	mobilePrivato: null,
                                	flAttivo: 0,
                                	dataNascita: null,
                                	durata: null,
                                	registratoSpid: 0,
                                	strutturaAfferenza: null,
                                	tipoDocumentoIdentita: null,
                                	tipologiaUtente: null,
                                	categoria: 0,
                                	fonte: 'ESTERNO'
                                };
                            },
                            'tipologieEsterniList': ['TipologieEsterniService', function(TipologieEsterniService) {
                                return TipologieEsterniService.external.get({}).$promise;
                            }],
                            'durataList': ['EsterniDurataService', function(EsterniDurataService) {
                                return EsterniDurataService.external.get({}).$promise;
                            }],
                            'struttureList': ['StruttureService', function(StruttureService) {
                                return StruttureService.external.get({}).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                    	$state.go('esterni', null, { reload: true });
                    }, function() {
                        $state.go('esterni');
                    })
                }]
            })
            .state('new-request-docente', {
                parent: 'home',
                url: '/new-request-docente',
                data: {
                    authorities: ['ROLE_CREAZIONE_ESTERNI'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                	$uibModal.open({
                        templateUrl: 'app/entities/esterni/new-esterni-dialog.html',
                        controller: 'NewEsterniController',
                        controllerAs: 'esterniCtrl',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                	id: null,
                                	dataCreazione: null,
                                	dataInizio: null,
                                	dataFine: null,
                                	dataEliminazione: null,
                                	dataValiditaDocumento: null,
                                	codiceFiscale: null,
                                	documentoIdendita: null,
                                	nazionalita: null,
                                	nome: null,
                                	cognome: null,
                                	genere: null,
                                	luogoNascita: null,
                                	emailPrivata: null,
                                	mobilePrivato: null,
                                	flAttivo: 0,
                                	dataNascita: null,
                                	durata: null,
                                	registratoSpid: 0,
                                	strutturaAfferenza: null,
                                	tipoDocumentoIdentita: null,
                                	tipologiaUtente: null,
                                	categoria: 0,
                                	fonte: 'ESTERNO'
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('esterni');
                                return $translate.refresh();
                            }],
                            'tipologieEsterniList': ['TipologieEsterniService', function(TipologieEsterniService) {
                                return TipologieEsterniService.external.get({}).$promise;
                            }],
                            'durataList': ['EsterniDurataService', function(EsterniDurataService) {
                                return EsterniDurataService.external.get({}).$promise;
                            }],
                            'struttureList': ['StruttureService', function(StruttureService) {
                                return StruttureService.external.getFromUser({}).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                    	$state.go('home', null, { reload: true });
                    }, function() {
                        $state.go('home');
                    })
                }]
            })
            
//            .state('esterni.registration', {
//                parent: 'account',
//                url: '/registration/{token}',
//                data: {
//                	authorities: [],
//                    pageTitle: 'unitoLandingApp.esterni.detail.title'
//                },
//                views: {
//                    'content@': {
//                        templateUrl: 'app/entities/esterni/new-esterni-registration.html',
//                        controller: 'NewEsterniRegistrationController'
//                    }
//                },
//                resolve: {
//                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
//                        $translatePartialLoader.addPart('esterni');
//                        $translatePartialLoader.addPart('register');
//                        return $translate.refresh();
//                    }],
//                    entity: ['$stateParams', 'Esterni', function($stateParams, Esterni) {
//                        return Esterni.getRequest({requestToken : $stateParams.token});
//                    }],
//                    'tipiDocIdentitaList': ['TipiDocIdentitaService', function(TipiDocIdentitaService) {
//                        return TipiDocIdentitaService.external.get({}).$promise;
//                    }],
//                    'tipologieEsterniList': ['TipologieEsterniService', function(TipologieEsterniService) {
//                        return TipologieEsterniService.external.get({}).$promise;
//                    }],
//                    'durataList': ['EsterniDurataService', function(EsterniDurataService) {
//                        return EsterniDurataService.external.get({}).$promise;
//                    }],
//                    'struttureList': ['StruttureService', function(StruttureService) {
//                        return StruttureService.external.get({}).$promise;
//                    }]
//                }
//            })
            .state('esterni.edit', {
                parent: 'esterni',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_GESTIONE_ESTERNI'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal','Esterni', function($stateParams, $state, $uibModal,Esterni) {
                	
                	
                	Esterni.get({id : $stateParams.id}, function(result) {
                		if(result!=null){
	            			$uibModal.open({
	                            templateUrl: 'app/entities/esterni/esterni-dialog.html',
	                            controller: 'EsterniDialogController',
	                            controllerAs: 'esterniCtrl',
	                            size: 'lg',
	                            resolve: {
	                                'tipologieEsterniList': ['TipologieEsterniService', function(TipologieEsterniService) {
	                                    return TipologieEsterniService.external.get({}).$promise;
	                                }],
	                                'tipiDocIdentitaList': ['TipiDocIdentitaService', function(TipiDocIdentitaService) {
	                                    return TipiDocIdentitaService.external.get({}).$promise;
	                                }],
	                                'durataList': ['EsterniDurataService', function(EsterniDurataService) {
	                                    return EsterniDurataService.external.get({}).$promise;
	                                }],
	                                'entity': ['Esterni', function(Esterni) {
	                                    return Esterni.get({id : $stateParams.id}).$promise;
	                                }],
	                                'struttureList': ['StruttureService', function(StruttureService) {
	                                    return StruttureService.external.getFromUser({}).$promise;
	                                }]
	                            }
	                        }).result.then(function(result) {
	                            $state.go('esterni', null, { reload: true });
	                        }, function() {
	                            $state.go('^');
	                        });
                		}
                    });
                	
                	
                }]
            })
            .state('esterni.delete', {
	            parent: 'esterni',
	            url: '/{id}/delete',
	            data: {
	                authorities: ['ROLE_GESTIONE_ESTERNI']
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/entities/esterni/esterni-delete-dialog.html',
	                    controller: 'EsterniDeleteController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                    resolve: {
	                        entity: ['Esterni', function(Esterni) {
	                            return Esterni.get({id : $stateParams.id}).$promise;
	                        }]
	                    }
	                }).result.then(function() {
	                    $state.go('esterni', null, { reload: 'esterni' });
	                }, function() {
	                    $state.go('^');
	                });
	            }]
            });
            
    	}
})();
