'use strict';

angular.module('unitoLandingApp')
.factory('AnagraficaService', ['$resource',function ($resource) {
	return {
		organizzatori: $resource('api/organizzatori', {}, {
			'get': { method: 'GET',
				params: {},
				isArray: true,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			}
			
		}),
		anagrafiche: $resource('api/anagrafiche', {}, {
			'get': { method: 'GET',
				params: {},
				isArray: true,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			}
			
		}),
		anagrafica: $resource('api/anagrafica/:id', {}, {
			'get': { method: 'GET',
				params: {},
				isArray: false,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			}
			
		}),
		ruStudenti: $resource('api/ru-studenti', {}, {
			'get': { method: 'GET',
				params: {},
				isArray: true,
				transformResponse: function (data) {
					data = angular.fromJson(data);
					return data;
				}
			}
			
		}),
		checkPersonalInformation: $resource('api/checkPersonalInformation', {}, {
			'check': { method: 'GET',
						params: {},
						isArray: false,
						transformResponse: function (data) {
							data = angular.fromJson(data);
							return data;
						}
			}
		}),
		getUserType: $resource('api/userType', {}, {
			'get': { method: 'GET',
				isArray: false
			}
        })

	};
}]);
