(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('service-provider', {
            parent: 'entity',
            url: '/service-provider',
            data: {
                authorities: ['ROLE_CREAZIONE_SP','ROLE_APPROVAZIONE_SP'],
                pageTitle: 'unitoLandingApp.service_provider.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service-provider/service-providers.html',
                    controller: 'Service_providerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('service_provider');
                    $translatePartialLoader.addPart('distribution_list');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('service-provider-detail', {
            parent: 'service-provider',
            url: '/service-provider/{id}',
            data: {
                authorities: ['ROLE_CREAZIONE_SP','ROLE_APPROVAZIONE_SP'],
                pageTitle: 'unitoLandingApp.service_provider.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service-provider/service-provider-detail.html',
                    controller: 'Service_providerDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('service_provider');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Service_provider', function($stateParams, Service_provider) {
                    return Service_provider.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'service-provider',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('service-provider-detail.edit', {
            parent: 'service-provider-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_CREAZIONE_SP']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-provider/service-provider-dialog.html',
                    controller: 'Service_providerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Service_provider', function(Service_provider) {
                            return Service_provider.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-provider.new', {
            parent: 'service-provider',
            url: '/new',
            data: {
                authorities: ['ROLE_CREAZIONE_SP']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-provider/service-provider-dialog.html',
                    controller: 'Service_providerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                description: null,
                                is_active: false,
                                is_approved: false,
                                whoCreated: 'current-user',
                                whoApproved: null,
                                metadata: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('service-provider', null, { reload: 'service-provider' });
                }, function() {
                    $state.go('service-provider');
                });
            }]
        })
        .state('service-provider.edit', {
            parent: 'service-provider',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_CREAZIONE_SP']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-provider/service-provider-dialog.html',
                    controller: 'Service_providerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Service_provider', function(Service_provider) {
                            return Service_provider.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('service-provider', null, { reload: 'service-provider' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-provider.delete', {
            parent: 'service-provider',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_CREAZIONE_SP']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-provider/service-provider-delete-dialog.html',
                    controller: 'Service_providerDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Service_provider', function(Service_provider) {
                            return Service_provider.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('service-provider', null, { reload: 'service-provider' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service-provider.new-list', {
            parent: 'service-provider',
            url: '/list/new',
            data: {
                authorities: ['ROLE_GESTIONE_LISTE_DISTRIBUZIONE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/distribution-list/distribution-list-dialog.html',
                    controller: 'Distribution_listDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                name: null,
                                description: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('service-provider', null, { reload: 'service-provider' });
                }, function() {
                    $state.go('service-provider');
                });
            }]
        })
        .state('service-provider.comments', {
            parent: 'service-provider',
            url: '/{id}/comments',
            data: {
                authorities: ['ROLE_CREAZIONE_SP','ROLE_APPROVAZIONE_SP']
            },
            params: {
                hasNewComment: null
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service-provider/service-provider-comments.html',
                    controller: 'Service_providerCommentsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Service_provider', function(Service_provider) {
                            return Service_provider.get({id : $stateParams.id}).$promise;
                        }],
                        commentEntity: function () {
                            return {
                                comment: null,
                                create_date: null,
                                id: null,
                                read: false
                            };
                        },
                        hasNewComments: function(){
                        	if($stateParams.hasNewComment != null && $stateParams.hasNewComment >-1){
                        		return true;
                        	}
                        	else{
                        		return false
                        	}
                        }
                    }
                }).result.then(function() {
                    $state.go('service-provider', null, { reload: 'service-provider' });
                }, function() {
                    $state.go('^',null,{ reload: 'service-provider' });
                });
            }]
        });
    }

})();
