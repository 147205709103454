'use strict';

angular.module('unitoLandingApp')
	.factory('CategorieService', ['$resource',function ($resource) {
		return {
			external: $resource('api/public/esterniCategorie', {}, {
					'get': { method: 'GET',
								params: {},
								isArray: true,
								transformResponse: function (data) {
									data = angular.fromJson(data);
									return data;
								}
                }
			})
		};
	}]);
