(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Distribution_listDialogController', Distribution_listDialogController);

    Distribution_listDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Distribution_list'];

    function Distribution_listDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Distribution_list) {
        var vm = this;

        vm.distribution_list = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.distribution_list.id !== null) {
                Distribution_list.update(vm.distribution_list, onSaveSuccess, onSaveError);
            } else {
                Distribution_list.save(vm.distribution_list, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('unitoLandingApp:distribution_listUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
