(function() {
    'use strict';
    angular
        .module('unitoLandingApp')
        .factory('Service_provider', Service_provider)
        .factory('SpCommentService',SpCommentService);

    Service_provider.$inject = ['$resource'];
    SpCommentService.$inject = ['$resource'];

    function Service_provider ($resource) {
        var resourceUrl =  'api/service-providers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    function SpCommentService ($resource){
    	  return $resource('api/service-providers/:id/new-comment', {}, {
    		  query: {method:'GET', params:{page:'@page'}, isArray:false},
    		  'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
    		setCommentsRead: {
    			method:'GET', 
    			url:'api/service-providers/:id/set-comments-has-read', 
    			isArray:false
    		},
    	   
    	  });
	}
    
})();
