(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('ServiceDialogController', ServiceDialogController);

    ServiceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Service', 'Module','DataUtils'];

    function ServiceDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Service, Module,DataUtils) {
        var vm = this;

        vm.service = entity;
        vm.clear = clear;
        vm.save = save;
        vm.modules = Module.query();
        vm.users = vm.service.anagrafiche;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.service.anagrafiche = vm.users;
            if (vm.service.id !== null) {
                Service.update(vm.service, onSaveSuccess, onSaveError);
            } else {
                Service.save(vm.service, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('unitoLandingApp:serviceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        vm.setImage = function ($file, service) {
        	if ($file && $file.$error === 'pattern') {
        		return;
        	}
        	if ($file) {
        		DataUtils.toBase64($file, function(base64Data) {
        			$scope.$apply(function() {
        				service.image = base64Data;
        				service.imageContentType = $file.type;
        			});
        		});
        	}
        }

    }
})();
