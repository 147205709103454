(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .factory('PasswordResetValidate', PasswordResetValidate);

    PasswordResetValidate.$inject = ['$resource'];

    function PasswordResetValidate($resource) {
        var service = $resource('api/account/reset_password/validate', {}, {});

        return service;
    }
})();
