'use strict';

angular.module('unitoLandingApp')
    .controller('RejectedRowController', 
    		['$scope', 'RejectedRow', 'RejectedRowFilter', 'RejectedType','ParseLinks', 
    		 function ($scope, RejectedRow, RejectedRowFilter, RejectedType,ParseLinks) {
        $scope.rejectedRows = [];
        $scope.page = 0;
        
        $scope.idJobFilter = 0;
        $scope.idTypeFilter = 0;
        
        $scope.filterIdJobList = [];
        $scope.filterRejectedTypeList = [];
        
        
        
        $scope.getIdJobList = function(){
        	RejectedRowFilter.query({}, function(result, headers) {
        		$scope.filterIdJobList = result;
        	});
        };
        $scope.getRejectedTypeList = function(){
        	RejectedType.query({}, function(result, headers) {
        		$scope.filterRejectedTypeList = result;
        	});
        };
        
        $scope.loadAll = function() {
        	if($scope.idJobFilter == null || $scope.idJobFilter=="")
        		$scope.idJobFilter = 0;
        	if($scope.idTypeFilter == null || $scope.idTypeFilter=="")
                $scope.idTypeFilter = 0;
        	
            RejectedRow.query({page: $scope.page, size: 20, sort:'id,desc', idJob:$scope.idJobFilter, idType:$scope.idTypeFilter}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.rejectedRows = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();
        $scope.getIdJobList();
        $scope.getRejectedTypeList();

        $scope.delete = function (id) {
            RejectedRow.get({id: id}, function(result) {
                $scope.rejectedRow = result;
                $('#deleteRejectedRowConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            RejectedRow.delete({id: id},
                function () {
                    $scope.loadAll();
                    $('#deleteRejectedRowConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.rejectedRow = {
                idJob: null,
                rejectedTypeId: null,
                payload: null,
                cause: null,
                id: null
            };
        };
    }]);
