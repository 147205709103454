(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Product_trDetailController', Product_trDetailController);

    Product_trDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Product_tr', 'Product'];

    function Product_trDetailController($scope, $rootScope, $stateParams, previousState, entity, Product_tr, Product) {
        var vm = this;

        vm.product_tr = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('unitoLandingApp:product_trUpdate', function(event, result) {
            vm.product_tr = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
