'use strict';

angular.module('unitoLandingApp')
    .controller('DashboardController', ['$scope','Principal','ScheduledJobStats', function ($scope, Principal, ScheduledJobStats) {
        Principal.identity().then(function(account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });
        
    }])
    .controller('WidgetLast10Ctrl', [ '$scope','ScheduledJob', function($scope, ScheduledJob) {
    	
    	  $scope.loadLast10 = function() {
              ScheduledJob.query({page: 0, size: 10, sort: ['id,desc', 'id']}, function(result, headers) {
                  $scope.scheduledJobs = result;
              });
          };
          
          $scope.loadLast10(); 
    	
    }])
    .controller('WidgetLast5ErrorsCtrl', [ '$scope','ScheduledJobErrors', function($scope, ScheduledJobErrors) {
    	$scope.loadLast5Errors = function() {
    		ScheduledJobErrors.query({page: 0, size: 5, sort: ['id,desc', 'id']}, function(result, headers) {
                $scope.scheduledJobs = result;
            });
        };
        
        $scope.loadLast5Errors();
    }])
    .controller("DoughnutCtrl", ['$scope', 'ScheduledJobStats',function ($scope, ScheduledJobStats) {
    		
    	 $scope.loadWidgetExecutionsData = function() {
         	ScheduledJobStats.query( function( result, headers ) {
         		$scope.widgetExecutionsData = result;
         		
         		
         		var labels = [];
         		var data = [];
         		var labels_percent = [];
         		var data_percent = [];
         		var totals = 0;
         		var colours = [];
         		var options =  {
         				legend: {
         					display: true,
         					labels: {
         						fontColor: 'rgb(255, 99, 132)'
         					}
         				}
         		};
         		
         		
         		result.forEach(function(entry) {
         			labels.push(entry.status); 	
         			if ( entry.status == 'SUCCESS') {
         				colours.push('#009900');
         			} else if ( entry.status == 'ERROR') {
         				colours.push('#FF0000');
         			} else {
         				colours.push('#DCDCDC');
         			}
         			
         			data.push(entry.numEntries);
         		   			
         			totals += entry.numEntries;
         		});
         		
         		
         		result.forEach(function(entry) {
         			labels_percent.push(entry.status);
         			data_percent.push( Math.round( entry.numEntries * 100 / totals ));
         		});
         		
         		$scope.labels_count = labels;
         		$scope.data_count = data;
         		$scope.totals_count = totals;
         		$scope.colours_count = colours;
         		
         		
         		$scope.labels_percent = labels_percent;
         		$scope.data_percent = data_percent;
         		$scope.colours_percent = colours;
         		
         		$scope.options = options;
         		
         	});
         };
         
         $scope.loadWidgetExecutionsData();
         
    	//	$scope.labels = ["Download Sales", "In-Store Sales", "Mail-Order Sales"];
    	//	$scope.data = [300, 500, 100];
    }])
     .controller("BarCtrl", ['$scope', 'ScheduledJobStats',function ($scope, ScheduledJobStats) {
    		
    	 $scope.loadWidgetExecutionsData = function() {
         	ScheduledJobStats.query( function( result, headers ) {
         		$scope.widgetExecutionsData = result;
         		
         		
         		var labels = ['Esecuzioni'];
         		var data = [];
         		var series = ['SUCCESS','ERROR', 'SKIPPED'];
         		var totals = 0;
         		var colours = ['#009900','#FF0000','#DCDCDC'];
         		var options =  {
         				legend: {
         					display: true,
         					labels: {
         						fontColor: 'rgb(255, 99, 132)'
         					}
         				}
         		};
         		
         		result.forEach(function(entry) {
         			
         			if ( entry.status == 'SUCCESS') {
         				data.splice(0,0, [entry.numEntries]);
         			} else if ( entry.status == 'ERROR') {
         				data.splice(1,0, [entry.numEntries]);
         			} else if ( entry.status = 'SKIPPED') {
         				data.splice(2,0, [entry.numEntries]);
         			}
         			  		
         		   			
         			totals += entry.numEntries;
         		});
         		
         		$scope.labels = labels;
                $scope.series = series;

         		$scope.data = data;
       
         		$scope.totals = totals;
         		$scope.colours = colours;
         		
         		$scope.options = options;
         		
         	});
         };
         
         $scope.loadWidgetExecutionsData();
         
    }]);
    
