(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Service_providerDeleteController',Service_providerDeleteController);

    Service_providerDeleteController.$inject = ['$uibModalInstance', 'entity', 'Service_provider'];

    function Service_providerDeleteController($uibModalInstance, entity, Service_provider) {
        var vm = this;

        vm.service_provider = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Service_provider.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
