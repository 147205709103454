(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Sp_commentiDetailController', Sp_commentiDetailController);

    Sp_commentiDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Sp_commenti', 'Jhi_user', 'Service_provider'];

    function Sp_commentiDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Sp_commenti, Jhi_user, Service_provider) {
        var vm = this;

        vm.sp_commenti = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('unitoLandingApp:sp_commentiUpdate', function(event, result) {
            vm.sp_commenti = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
