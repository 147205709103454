'use strict';

angular.module('unitoLandingApp').controller('EccezioniDetailController', 
		['$scope', '$rootScope', '$stateParams', 'entity', 'Eccezioni',function ($scope, $rootScope, $stateParams, entity, Eccezioni) {
        $scope.eccezioni = entity;
        $scope.load = function (id) {
            Eccezioni.get({id: id}, function(result) {
                $scope.eccezioni = result;
            });
        };
        $rootScope.$on('unitoLandingApp:eccezioniUpdate', function(event, result) {
            $scope.eccezioni = result;
        });
    }]);
