'use strict';

angular.module('unitoLandingApp').controller('EccezioniDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Eccezioni', 'Omonimie', '$q',
        function($scope, $stateParams, $uibModalInstance, entity, Eccezioni, Omonimie, $q) {

        $scope.eccezioni = entity;

        
        $scope.eccezioneCopy = {};
        
        if ( $stateParams.id !== undefined ) {
	        entity.$promise.then(function( ecc ) {
	        	angular.copy(ecc, $scope.eccezioneCopy);
	        });
        }
               
        
        
        $scope.load = function(id) {
            Eccezioni.get({id : id}, function(result) {
                $scope.eccezioni = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('unitoLandingApp:eccezioniUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function () {
            if ($scope.eccezioni.id != null) {
                Eccezioni.update($scope.eccezioni, onSaveFinished);
            } else {
                Eccezioni.save($scope.eccezioni, onSaveFinished);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        
        $scope.checkUniqueUsername = function(username) {
        	var isValid = false;
        	var deferred = $q.defer();
        	Omonimie.get( { username: username }, function( resp ) {
        		var counter = resp.counter;
        		if (counter == 0) {
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		deferred.reject(isValid);
        	}, function( err ) {
        		deferred.reject(err);
        	});
        	return deferred.promise;
        };
}]);
