(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('GestioneUtentiGruppiController', GestioneUtentiGruppiController);

    GestioneUtentiGruppiController.$inject = ['$state','$sce','UNITO_USERS_URL','UNITO_ATTRIBUTES_URL' ];

    function GestioneUtentiGruppiController($state, $sce,UNITO_USERS_URL,UNITO_ATTRIBUTES_URL) {

        var vm = this;

        vm.usersUrl = $sce.trustAsResourceUrl(UNITO_USERS_URL);
        vm.attributesUrl = $sce.trustAsResourceUrl(UNITO_ATTRIBUTES_URL);
        
    }
})();
