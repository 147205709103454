'use strict';

angular.module('unitoLandingApp').factory('Esterni', 
		['$resource', 'DateUtils',function ($resource, DateUtils) {
        return $resource('api/esternis/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'fornitori': { method: 'GET', url: 'api/fornitori',isArray: true},
            'currentEsterno': {
                method: 'GET',
                url: 'api/esternis/current',
                transformResponse: function (data) {
                	if(data!=null && data.length>0)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'activate': {
                method: 'GET',
                url: 'api/activateUser/:id',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                url: 'api/public/esternis',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'newEsterniRequest': {
                method: 'POST',
                url: 'api/new-esterni-request',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'newEsterniComplete': {
                method: 'PUT',
                url: 'api/public/new-esterni-complete',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'getRequest': {
                method: 'GET',
                url: 'api/public/new-esterni-request/:requestToken',
                transformResponse: function (data) {
                	if(data!=null && data!="")
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'confirmRegistration': {
                method: 'GET',
                url: 'api/public/confirm-registration/:token',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'getCountryList': {
                method: 'GET',
                url: 'api/public/iso-country-codes',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }]);
