(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Iso_country_codesDeleteController',Iso_country_codesDeleteController);

    Iso_country_codesDeleteController.$inject = ['$uibModalInstance', 'entity', 'Iso_country_codes'];

    function Iso_country_codesDeleteController($uibModalInstance, entity, Iso_country_codes) {
        var vm = this;

        vm.iso_country_codes = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Iso_country_codes.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
