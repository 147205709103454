(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('gestione-utenti', {
            parent: 'app',
            url: '/gestione-utenti',
            data: {
                authorities: ['ROLE_GESTIONE_UTENTI'],
                pageTitle: 'gestioneUtentiGruppi.gestioneUtenti.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/gestioneUtentiGruppi/user-management.html',
                    controller: 'GestioneUtentiGruppiController',
                    controllerAs: 'vm'
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gestione-utenti-gruppi');
                    return $translate.refresh();
                }]

            }        
        })
        .state('gestione-gruppi', {
            parent: 'app',
            url: '/gestione-gruppi',
            data: {
                authorities: ['ROLE_GESTIONE_GRUPPI'],
                pageTitle: 'gestioneUtentiGruppi.gestioneGruppi.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/gestioneUtentiGruppi/group-management.html',
                    controller: 'GestioneUtentiGruppiController',
                    controllerAs: 'vm'
                }
            }, 
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gestione-utenti-gruppi');
                    return $translate.refresh();
                }]

            }        
        });
    }
})();
