(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('unitoLandingApp')
        .constant('VERSION', "1.65-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('UNITO_USERS_URL', "https://unito-authservice.ose.private.cineca.it/authservice/?embed=1&theme=unito#/users")
        .constant('UNITO_ATTRIBUTES_URL', "https://unito-authservice.ose.private.cineca.it/authservice/?embed=1&theme=unito#/attributes")
;
})();
