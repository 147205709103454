(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Tenant_trDialogController', Tenant_trDialogController);

    Tenant_trDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Tenant_tr', 'Tenant'];

    function Tenant_trDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Tenant_tr, Tenant) {
        var vm = this;

        vm.tenant_tr = entity;
        vm.clear = clear;
        vm.save = save;
        vm.tenants = Tenant.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.tenant_tr.id !== null) {
                Tenant_tr.update(vm.tenant_tr, onSaveSuccess, onSaveError);
            } else {
                Tenant_tr.save(vm.tenant_tr, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('unitoLandingApp:tenant_trUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
