'use strict';

angular.module('unitoLandingApp').controller('FornitoriDetailController',
		['$scope', '$rootScope', '$stateParams', 'entity', 'Esterni','AnagraficaService',function ($scope, $rootScope, $stateParams, entity, Esterni,AnagraficaService) {
        $scope.esterni = entity;
        if($scope.esterni.userId!=null){
        	AnagraficaService.getUserType.get({userId:$scope.esterni.userId}, function(data){
    			if(data!=null){
    				$scope.macroCategoria = data.macroCategoria;
	    			$scope.categoria = data.categoria;
	    			$scope.sottoCategoria = data.sottoCategoria;
	    			$scope.codRuolo = data.codRuolo;
	    			$scope.inquadramento = data.inquadramento;
	    			$scope.statoGenerale = data.statoGenerale;
	    			$scope.statoSpecifico = data.statoSpecifico;
    			}
    		});
    	}
        $scope.load = function (id) {
            Esterni.get({id: id}, function(result) {
                $scope.esterni = result;
            });
        };
        $rootScope.$on('unitoLandingApp:esterniUpdate', function(event, result) {
            $scope.esterni = result;
            
        });
    }]);
