'use strict';

angular.module('unitoLandingApp')
    .factory('TipologieTelefonoService', ['$resource', 'DateUtils',function ($resource, DateUtils) {
        return $resource('api/tipologie-telefono', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }]);

