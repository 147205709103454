(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Distribution_listController', Distribution_listController);

    Distribution_listController.$inject = ['Distribution_list'];

    function Distribution_listController(Distribution_list) {

        var vm = this;

        vm.distribution_lists = [];

        loadAll();

        function loadAll() {
            Distribution_list.query(function(result) {
                vm.distribution_lists = result;
                vm.searchQuery = null;
            });
        }
    }
})();
