'use strict';

angular.module('unitoLandingApp').controller('ConfigParamDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ConfigParam',
        function($scope, $stateParams, $uibModalInstance, entity, ConfigParam) {

        $scope.configParam = entity;
        $scope.load = function(id) {
            ConfigParam.get({id : id}, function(result) {
                $scope.configParam = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('unitoLandingApp:configParamUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function () {
            if ($scope.configParam.id != null) {
                ConfigParam.update($scope.configParam, onSaveFinished);
            } else {
                ConfigParam.save($scope.configParam, onSaveFinished);
            }
        };

        $scope.clear = function() {
        	$uibModalInstance.dismiss('cancel');
        };
}]);
