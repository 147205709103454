(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$timeout', 'Auth', 'LoginService','$window','$location'];

    function ResetFinishController ($stateParams, $timeout, Auth, LoginService,$window,$location) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.keyValidated = vm.keyMissing;
        vm.keyInvalid = false;
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = LoginService.open;
        vm.resetAccount = {};
        vm.success = null;
        vm.errorPPolicy = null;
        
        if (!vm.keyMissing) {
            Auth.resetPasswordValidate($stateParams.key).then(function () {
            	vm.keyValidated = true;

                $timeout(function (){angular.element('#password').focus();});
            }).catch(function (response) {
            	console.log(response);
            	vm.keyValidated = true;
                vm.keyInvalid = true;
                vm.keyMissing = true;
            });
        }

        function goToAuth(){
        	var homePage = 'https://my.unito.it';
        	if ($location.host() == 'unito-appserver.sidp.pp.cineca.it') {
        		homePage = 'http://my-preprod.unito.it/';
        	}
        	$window.location.href = homePage;
        }

        function finishReset() {
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorPPolicy = null;
            vm.success = null;
            if (vm.resetAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.resetAccount.password}).then(function () {
                    vm.success = 'OK';
                    $timeout(goToAuth, 3000);
                }).catch(function (response) {
                	if(response.data === 'InvalidPassword')
                		vm.errorPPolicy = 'InvalidPassword';
                	else
                		vm.error = 'ERROR';
                });
            }
        }
    }
})();
