(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Service_providerDetailController', Service_providerDetailController);

    Service_providerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Service_provider', 'Distribution_list'];

    function Service_providerDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Service_provider, Distribution_list) {
        var vm = this;

        vm.service_provider = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        
        $scope.aceLoaded = function(_editor){
			// Editor part
			var _session = _editor.getSession();
			var _renderer = _editor.renderer;

			// Options
			_session.setUndoManager(new ace.UndoManager());
			_editor.setHighlightActiveLine(true);
			_editor.setHighlightSelectedWord(true);
			_editor.setShowPrintMargin(false);

		};
		
		$scope.aceOptions = {
				useWrapMode : true,
				showGutter: true,
				mode: 'xml',
				firstLineNumber: 1,
				theme: 'tomorrow',
				onLoad: $scope.aceLoaded
		};

        var unsubscribe = $rootScope.$on('unitoLandingApp:service_providerUpdate', function(event, result) {
            vm.service_provider = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
