'use strict';

angular.module('unitoLandingApp')
	.factory('ComuniCodiceFiscaleService', ['$resource',function ($resource) {
		return $resource('api/public/comuni-codice-fiscale', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });

	}]);
