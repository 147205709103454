(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .filter('startFrom', function() {
		    return function(input, start) {
		        start = +start; //parse to int
		        return input.slice(start);
		    }
		})
        .controller('Service_providerCommentsController', Service_providerCommentsController);

    Service_providerCommentsController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'commentEntity','hasNewComments','Service_provider','SpCommentService'];

    function Service_providerCommentsController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, commentEntity, hasNewComments,Service_provider,SpCommentService) {
        var vm = this;
        vm.service_provider = entity;
        vm.newComment = commentEntity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        
        vm.totalItems = vm.service_provider.comments.length;
        vm.pageSize = 3;
        vm.pageNumber = Math.round(vm.totalItems/vm.pageSize);
        vm.currentPage = vm.pageNumber-1;
        
        if(hasNewComments == true){
        	SpCommentService.setCommentsRead({id:vm.service_provider.id},{},function(){
        		return;
        	},null);
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            SpCommentService.save({id : $stateParams.id},vm.newComment, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
        	vm.service_provider.comments.push(result);
        	vm.newComment.comment = null;

        	vm.totalItems = vm.service_provider.comments.length;
        	vm.pageNumber = Math.round(vm.totalItems/vm.pageSize);
        	vm.currentPage = vm.pageNumber-1;

        	$scope.editForm.$setPristine();
        	vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        $scope.getNumber = function(num) {
            return new Array(num);   
        }
        
        $scope.nextPage = function(){
        	if(vm.currentPage != (vm.pageNumber-1))
        		vm.currentPage++;
        }
        
        $scope.prevPage = function(){
        	if(vm.currentPage != 0)
        		vm.currentPage--;
        }
        
    }
})();
