(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', 'AnagraficaService'];

    function UserManagementDetailController($stateParams, AnagraficaService) {
        var vm = this;

        vm.load = load;
        vm.user = {};

        vm.load($stateParams.id);

        function load(id) {
        	AnagraficaService.anagrafica.get({id: id}, function(result) {
                vm.user = result;
            });
        }
    }
})();
