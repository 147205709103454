(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate','Esterni','CodiceFiscaleService','ComuniCodiceFiscaleService','$q','utenteEsterno','$window'];

    function SettingsController (Principal, Auth, JhiLanguageService,$translate,Esterni,CodiceFiscaleService,ComuniCodiceFiscaleService,$q,utenteEsterno,$window) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        
        
        vm.genderList = ['M','F'];
        vm.countryList = null;
        vm.savedCodFis = utenteEsterno.codiceFiscale;
//        getElencoComuni();
    	
        if(utenteEsterno!=null){
	        utenteEsterno.dataNascita = new Date(utenteEsterno.dataNascita);
	    	if(utenteEsterno.dataInizio!=null)
	    		utenteEsterno.dataInizio = new Date(utenteEsterno.dataInizio);
	    	else
	    		utenteEsterno.dataInizio = new Date();
	    	
	    	if(utenteEsterno.dataValiditaDocumento!=null)
	    		utenteEsterno.dataValiditaDocumento = new Date(utenteEsterno.dataValiditaDocumento);
        }
    	
    	vm.utenteEsterno = utenteEsterno;
        
        //Elenco stati
        Esterni.getCountryList({},function(response){
        	vm.countryList = response;
        });
        
        
       	
        function save () {
        	vm.alerts = null;
        	Esterni.update(vm.utenteEsterno,function(result, headers) {
        		vm.success = true;
            },function(){
            	 vm.success = null;
                 vm.error = 'ERROR';
            });
        	
        	$window.scrollTo(0, 0);

        }
        
        
        function transformDate(date) {
        	var formatted = pad(date.getDate(), 2) + "/" + pad(date.getMonth() + 1, 2) + "/" + date.getFullYear();
        	return formatted;
        }

        function pad(n, width, z) {
        	z = z || '0';
        	n = n + '';
        	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }
        
        //Verifica che non esista già un esterno con il codice fiscale inserito
        vm.checkUniqueCodiceFiscale = function(codFis,docIdentita) {
        	var reqObject = {
        		codiceFiscale : codFis,
        		documentoIdentita: docIdentita,
        		nazionalita: vm.utenteEsterno.nazionalita
        	};
        	var isValid = false;
        	var deferred = $q.defer();
        	CodiceFiscaleService.checkUniqueCodiceFiscale.check( reqObject, function(resp) {
        		isValid = resp.isUnique;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		//Il codice fiscale esiste perchè è quello dell'utente corrente.
        		else if(vm.savedCodFis != null && vm.savedCodFis === codFis){
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        //Verifica la validità del codice fiscale
        vm.checkCodiceFiscale = function(codfis) {
        	var isValid = false;
        	var deferred = $q.defer();
        	CodiceFiscaleService.checkCodiceFiscale.check( codfis, function(resp) {
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        //Verifica la validità del codice fiscale rispetto alla data di nascita inserita
        vm.checkDateCodiceFiscale = function(dataNascita, codiceFiscale) {
        	var isValid = false;
        	var deferred = $q.defer();
        	var inputDate =  (dataNascita == null ? new Date() : dataNascita);
        	var payload = {
					"dataNascita": transformDate( inputDate ),
					"codiceFiscale" : codiceFiscale
			}; 
        	CodiceFiscaleService.checkDateCodiceFiscale.check( payload, function(resp) {
        	
        		isValid = resp.isValid;
        		if(isValid) {
        			deferred.resolve(isValid);
        		}
        		else {
        			deferred.reject(false);
        		}
        	}, function(err) {
        			deferred.reject(false);
        	});
        	return deferred.promise;
        };
        
        vm.changeStep = function(value){
        	vm.stepConfirmation = value;
        }
        
        //Verifica la validità del codice fisfale rispetto al genere inserito
        vm.checkGenderCodFiscale = function(gender,codiceFiscale){
        	var isValid = false;
        	var deferred = $q.defer();
        	
        	if(codiceFiscale.length>=10){
        		var giornoNascita = codiceFiscale.substring(9,11);
        		if( (giornoNascita>=40 && gender==='F') || (giornoNascita<40 && gender==='M') ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        	}
        	return deferred.promise;
        };
        
        
        
        vm.checkLuogoCodFiscale = function(luogo,codiceFiscale){
        	var isValid = false;
        	var deferred = $q.defer();
        	if(codiceFiscale.length==16 && luogo!=null){
        		var codiceComune = codiceFiscale.substring(11,15);
        		if(codiceComune===luogo ){
        			isValid = true;
        			deferred.resolve(isValid);
        		}
        		else{
        			deferred.reject(false);
        		}
        		
        	}
        	else{
        		isValid = true;
    			deferred.resolve(isValid);
        	}
        	
        	return deferred.promise;
        }
        
        vm.updateLuogoNascitaSelezionato = function(luogo){
        	vm.luogoNascitaSelezionato = luogo.codice;
        }
        
//        vm.appendSiglaProvincia = function(siglaProvincia){
//        	if(siglaProvincia!=null)
//        		return " ("+siglaProvincia+")";
//        	else
//        		return "";
//        }
//        
//        function getElencoComuni(){
//        	ComuniCodiceFiscaleService.query({}, onSuccess, onError);
//
//        	function onSuccess(data, headers) {
//        		vm.listaComuni = data;
//
//        	}
//        	function onError(error) {
//        		AlertService.error(error.data.message);
//        	}
//        }
    }
})();
