(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Distribution_listDetailController', Distribution_listDetailController);

    Distribution_listDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Distribution_list'];

    function Distribution_listDetailController($scope, $rootScope, $stateParams, previousState, entity, Distribution_list) {
        var vm = this;

        vm.distribution_list = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('unitoLandingApp:distribution_listUpdate', function(event, result) {
            vm.distribution_list = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
