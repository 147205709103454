(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['Auth', 'Principal','$stateParams','$http','$timeout','$window','$location'];

    function PasswordController (Auth, Principal,$stateParams,$http,$timeout,$window,$location) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.errorOldPassword = null;
        vm.errorPPolicy = null;
        vm.us_account=$stateParams.us_account;

        if(vm.us_account){
        	vm.account = {};
        	getUserName();
        	
        }else{
	        Principal.identity().then(function(account) {
	            vm.account = account;
	        });
        }
        
        function getUserName(){
        	$http.get('api/account/whois',{
        	    params: {us_account: vm.us_account}
        	 }).then(function(result) {
                
        		if(result.status == 200){
        			vm.account = result.data;
        		}
                
            });

        };
        
        function goToAuth(){
        	var homePage = 'https://my.unito.it';
        	if ($location.host() == 'unito-appserver.sidp.pp.cineca.it') {
        		homePage = 'http://my-preprod.unito.it/';
        	}
        	$window.location.href = homePage;
        }

        function changePassword () {
            vm.error = null;
            vm.success = null;
            vm.doNotMatch = null;
            vm.errorPPolicy = null;
            vm.errorOldPassword = null;
            if (vm.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.changePassword(vm.oldPassword,vm.password,vm.us_account).then(function () {
                    vm.success = 'OK';
                	$timeout(goToAuth, 3000);
                    
                }).catch(function (response) {
                	if(response.data === 'IncorrectOldPassword')
                		vm.errorOldPassword = 'IncorrectOldPassword';
                	else if(response.data === 'InvalidPassword')
                		vm.errorPPolicy = 'InvalidPassword';
                	else
                		vm.error = 'ERROR';
                });
            }
        }
    }
})();
