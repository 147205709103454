'use strict';

angular.module('unitoLandingApp').controller('RegistryEntitiesController', 
		['$scope','ParseLinks','Provider','$state','$stateParams','$window',function ($scope, ParseLinks,Provider,$state,$stateParams,$window) {

		function loadAll () {
			Provider.query({}, onSuccess, onError);
			function sort() {
				var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				$scope.collapsedNode = [];
				return result;
			}
			function onSuccess(data, headers) {
				$scope.data = data;
			}
			function onError(error) {
				AlertService.error(error.data.message);
			}
		};
		loadAll();

		$scope.collapsedNode = [];
		$scope.currentNodeType = null;
		$scope.selectedNode = null;

		$scope.showNodeInfo = function(node,type){
			$scope.selectedNode = node;
			$scope.currentNodeType = type;
			$window.scrollTo(0, 0);
		};


		/* Traduzioni */

		$scope.editTranslation = function(translation){
			switch($scope.currentNodeType){
			case 'provider':
				$state.go("provider-tr.edit",{id:translation.id});
				break;
			case 'product':
				$state.go("product-tr.edit",{id:translation.id});
				break;
			case 'module':
				$state.go("module-tr.edit",{id:translation.id});
				break;
			case 'service':
				$state.go("service-tr.edit",{id:translation.id});
				break;
			}
		};

		$scope.newTranslation = function(translation){
			switch($scope.currentNodeType){
			case 'provider':
				$state.go("provider-tr.new",{id: $scope.selectedNode.id});
				break;
			case 'product':
				$state.go("product-tr.new",{id: $scope.selectedNode.id});
				break;
			case 'module':
				$state.go("module-tr.new",{id: $scope.selectedNode.id});
				break;
			case 'service':
				$state.go("service-tr.new",{id: $scope.selectedNode.id});
				break;
			}

		};

		$scope.deleteTranslation = function(translation){
			switch($scope.currentNodeType){
			case 'provider':
				$state.go("provider-tr.delete",{id: translation.id});
				break;
			case 'product':
				$state.go("product-tr.delete",{id: translation.id});
				break;
			case 'module':
				$state.go("module-tr.delete",{id: translation.id});
				break;
			case 'service':
				$state.go("service-tr.delete",{id: translation.id});
				break;
			}
		};


		// Check if a state change happened
		$scope.$on('$stateChangeSuccess',function onStateSuccess(event, toState, toParams, fromState) {
			if(fromState.name.indexOf("delete")>-1){
				$scope.selectedNode = null;
				$scope.currentNodeType = null;
			}
			if(toState.name === "registryEntities"){
				loadAll();
			}

		});

		$scope.updateCurrentNode = function(node,type){
			if($scope.selectedNode != null){
				if(node.id===$scope.selectedNode.id && $scope.currentNodeType === type)
					$scope.selectedNode = node;
			}
		};



		$scope.remove = function (scope) {
			scope.remove();
		};

		$scope.mustExpand = function (scope) {
			scope.toggle();
		};

		$scope.collapseAll = function () {
			$scope.$broadcast('angular-ui-tree:collapse-all');
			$scope.collapsedNode = [];
		};

		$scope.expandAll = function () {
			$scope.$broadcast('angular-ui-tree:expand-all');
		};

		$scope.collapseNode = function (nodeId,type,scope){
			var obj={nodeId: nodeId,type:type};
			var index = $scope.collapsedNode.map(function(o) { 
				if(o.type === type)
					return o.nodeId; 
			}).indexOf(nodeId);
			
			if(index>-1)
				$scope.collapsedNode.splice(index, 1);
			else
				$scope.collapsedNode.push(obj);
			
			scope.toggle(scope);
		};
		
		$scope.triggerCollapse = function (nodeId,type,scope){
			
			var index = $scope.collapsedNode.map(function(o) { 
				if(o.type === type)
					return o.nodeId; 
			}).indexOf(nodeId);

			if(index>-1)
				scope.toggle(scope);
		};
		
	}      
]);
