(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Service_providerController', Service_providerController);

    Service_providerController.$inject = ['DataUtils', 'Service_provider','Principal','Account','$state'];

    function Service_providerController(DataUtils, Service_provider,Principal,Account,$state) {

        var vm = this;

        vm.service_providers = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.canApprove = Principal.hasAnyAuthority(['ROLE_APPROVATORE_SP']);
        vm.canCreateNewSp = Principal.hasAnyAuthority(['ROLE_CREAZIONE_SP']);
        
        vm.spWithNewComments = [];
        
        Principal.identity().then(function(account) {
            vm.account = account;
        });

        loadAll();
        
        vm.switchOptions = {
			onText : 'yes',
			offText : 'no', 
			isActive : true,
			size : 'medium',
			animate : true,
			radioOff : true,
			handleWidth : "auto",
			labelWidth : "auto",
			inverse : true,
			onColor : "unimib",
			offColor : "default",
			switchIsSelected : 'false'
		};
        

        
        function loadAll() {
            Service_provider.query(function(result) {
                vm.service_providers = result;
                vm.searchQuery = null;
            });
        };
        
        vm.toggleActivation = function(sp){
        	sp.is_active = !sp.is_active;
        	Service_provider.update(sp, onSaveSuccess, onSaveError);
        } ;
        
        vm.toggleApproved = function(sp){
        	sp.is_approved = !sp.is_approved;
        	sp.whoApproved = "current-user";
        	Service_provider.update(sp, onSaveSuccess, onSaveError);
        } ;
        
        function onSaveSuccess (result) {
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        
        vm.countNewMessages = function(sp){
        	var comments = sp.comments;
        	var counter = 0;
        	for(var i=(comments.length-1);i>=0;i--){
        		var comment = comments[i];
        		if(comment.read==null || comment.read==false){
        			var creatorUser = comment.who_commented;
        			if(creatorUser.login!=vm.account.login){
        				if(vm.spWithNewComments.indexOf(sp.id)==-1)
        					vm.spWithNewComments.push(sp.id);
        				counter++;
        			}
        		}
        	}

        	return counter;
        };
        
        vm.openComments = function(sp){
        	var param = {"id": sp.id,"hasNewComment":vm.spWithNewComments.indexOf(sp.id)};
        	$state.go('service-provider.comments', param);
        };
    }
})();
