(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService','AnagraficaService'];

    function UserManagementController(Principal, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService,AnagraficaService) {
        var vm = this;
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.users = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.loadAll();
        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });


        function loadAll () {
        	if(vm.nameFilter==="")
        		vm.nameFilter = null;
        	if(vm.profileFilter==="")
        		vm.profileFilter = null;
        	
        	AnagraficaService.ruStudenti.get({
        		page: pagingParams.page - 1,
        		size: vm.itemsPerPage,
        		sort: sort(),
        		filterName: vm.nameFilter
        	}, onSuccess, onError);
        	
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
        	if(vm.nameFilter==="")
        		vm.nameFilter = null;
        	if(vm.profileFilter==="")
        		vm.profileFilter = null;
        	
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                filterName: vm.nameFilter,
                filterRole: vm.profileFilter
            });
        }
    }
})();
