(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('ProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Product', 'Provider', 'Environment'];

    function ProductDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Product, Provider, Environment) {
        var vm = this;

        vm.product = entity;
        vm.clear = clear;
        vm.save = save;
        vm.providers = Provider.query();
        vm.environments = Environment.query();
        vm.users = vm.product.anagrafiche;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.product.anagrafiche = vm.users;
            
            //Set Enviroment
            if(vm.environments.length==1){
            	vm.product.environment = vm.environments[0];
            }
            	
            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('unitoLandingApp:productUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
