(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('service', {
            parent: 'entity',
            url: '/service?page&sort&search',
            data: {
                authorities: ['ROLE_REGISTRY'],
                pageTitle: 'unitoLandingApp.service.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service/services.html',
                    controller: 'ServiceController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('service');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('service-detail', {
            parent: 'service',
            url: '/service/{id}',
            data: {
                authorities: ['ROLE_REGISTRY'],
                pageTitle: 'unitoLandingApp.service.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/service/service-detail.html',
                    controller: 'ServiceDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('service');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Service', function($stateParams, Service) {
                    return Service.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'service',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('service-detail.edit', {
            parent: 'service-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_REGISTRY']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service/service-dialog.html',
                    controller: 'ServiceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Service', function(Service) {
                            return Service.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service.new', {
            parent: 'registryEntities',
            url: '/{id}/service/new',
            data: {
                authorities: ['ROLE_REGISTRY']
            },
            onEnter: ['$stateParams', '$state', '$uibModal','Module', function($stateParams, $state, $uibModal,Module) {
                $uibModal.open({
                    templateUrl: 'app/entities/service/service-dialog.html',
                    controller: 'ServiceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                profile: null,
                                id: null,
                                cantDelete:false,
                                module: Module.get({id:$stateParams.id})
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('registryEntities', null);
                }, function() {
                    $state.go('registryEntities');
                });
            }]
        })
        .state('service.edit', {
            parent: 'registryEntities',
            url: '/service/{id}/edit',
            data: {
                authorities: ['ROLE_REGISTRY']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service/service-dialog.html',
                    controller: 'ServiceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Service', function(Service) {
                            return Service.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('registryEntities', null);
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('service.delete', {
            parent: 'registryEntities',
            url: '/service/{id}/delete',
            data: {
                authorities: ['ROLE_REGISTRY']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/service/service-delete-dialog.html',
                    controller: 'ServiceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Service', function(Service) {
                            return Service.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('registryEntities', null);
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
