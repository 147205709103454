(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('virtualUser', {
                parent: 'entity',
                url: '/virtualUser?page&sort&search',
                data: {
                    authorities: ['ROLE_GESTIONE_UTENTI_VIRTUALI'],
                    pageTitle: 'unitoLandingApp.utenzeImpersonali.virtualUser.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/virtualUser/virtualUser.html',
                        controller: 'VirtualUserController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'userName,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                	pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('utenzeImpersonali');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('virtualUser.detail', {
                parent: 'entity',
                url: '/virtualUser-detail/{id}',
                data: {
                    authorities: ['ROLE_GESTIONE_UTENTI_VIRTUALI'],
                    pageTitle: 'unitoLandingApp.utenzeImpersonali.virtualUser.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/virtualUser/virtualUser-detail.html',
                        controller: 'VirtualUserDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('utenzeImpersonali');
                        $translatePartialLoader.addPart('esterni');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'UtenzeImpersonali', function($stateParams, UtenzeImpersonali) {
                        return UtenzeImpersonali.get({id : $stateParams.id});
                    }],
                    'struttureList': ['StruttureService', function(StruttureService) {
                        return StruttureService.external.get({}).$promise;
                    }]
                }
            })
            .state('virtualUser.new', {
                parent: 'virtualUser',
                url: '/new-virtualUser',
                data: {
                    authorities: ['ROLE_GESTIONE_UTENTI_VIRTUALI'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                	$uibModal.open({
                        templateUrl: 'app/entities/virtualUser/virtualUser-dialog.html',
                        controller: 'VirtualUserDialogController',
                        controllerAs: 'VirtualUserCtrl',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                	id: null,
                                	data: null,
                                	dataModifica: null,
                                	descrizioneServizio: null,
                                	tipologiaTelefono: null,
                                	userName: null,
                                	strutturaAfferenza: null,
                                	fonte: 'VIRTUAL_USER'
                                };
                            },
                            'struttureList': ['StruttureService', function(StruttureService) {
                                return StruttureService.external.get({}).$promise;
                            }],
                            'tipologieTelefonoList': ['TipologieTelefonoService', function(TipologieTelefonoService) {
                                return TipologieTelefonoService.get({}).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                    	$state.go('virtualUser', null, { reload: true });
                    }, function() {
                        $state.go('virtualUser');
                    })
                }]
            })
            .state('virtualUser.edit', {
                parent: 'virtualUser',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_GESTIONE_UTENTI_VIRTUALI'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal','UtenzeImpersonali', function($stateParams, $state, $uibModal,UtenzeImpersonali) {
        			$uibModal.open({
                        templateUrl: 'app/entities/virtualUser/virtualUser-dialog.html',
                        controller: 'VirtualUserDialogController',
                        controllerAs: 'VirtualUserCtrl',
                        size: 'lg',
                        resolve: {
                            'entity': ['UtenzeImpersonali', function(UtenzeImpersonali) {
                                return UtenzeImpersonali.get({id : $stateParams.id}).$promise;
                            }],
                            'struttureList': ['StruttureService', function(StruttureService) {
                                return StruttureService.external.get({}).$promise;
                            }],
                            'tipologieTelefonoList': ['TipologieTelefonoService', function(TipologieTelefonoService) {
                                return TipologieTelefonoService.get({}).$promise;
                            }]
        				
                        }
                    }).result.then(function(result) {
                        $state.go('virtualUser', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });

                }]
            })
            .state('virtualUser.delete', {
	            parent: 'virtualUser',
	            url: '/{id}/delete',
	            data: {
	                authorities: ['ROLE_GESTIONE_UTENTI_VIRTUALI']
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/entities/virtualUser/virtualUser-delete-dialog.html',
	                    controller: 'VirtualUserDeleteController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                    resolve: {
	                        entity: ['UtenzeImpersonali', function(UtenzeImpersonali) {
	                            return UtenzeImpersonali.get({id : $stateParams.id}).$promise;
	                        }]
	                    }
	                }).result.then(function() {
	                    $state.go('virtualUser', null, { reload: 'virtualUser' });
	                }, function() {
	                    $state.go('^');
	                });
	            }]
            });
            
    	}
})();
