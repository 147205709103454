(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$timeout', 'Auth', 'vcRecaptchaService', '$window'];

    function RequestResetController ($timeout, Auth, vcRecaptchaService, $window) {
        var vm = this;

        vm.error = null;
        vm.errorEmailNotExists = null;
        vm.errorMultipleAccounts = null;
        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.success = null;
        
    	vm.captchaValidated = null;
    	vm.publicCaptchaKey = $window.G_CAPTCHA_PUB_KEY;
        
        //vm.type = 2;

        $timeout(function (){angular.element('#email').focus();});

        function requestReset () {

            vm.error = null;
            vm.errorEmailNotExists = null;
            vm.errorMultipleAccounts = null;
            vm.errorAccountInactive = null;
            vm.success = null;
            vm.captchaValidated = null;
            
        	if(vcRecaptchaService.getResponse() === ""){//Deve validare il ReCaptcha
        		vm.captchaValidated = false;
        	}  
        	else{
        		vm.captchaValidated = true;
            
		        var objRequest = {};
		        //Email
		        if(vm.type==1)
		        	objRequest.mail = vm.resetAccount.email;
		        //userId
		        else if(vm.type==2)
		        	objRequest.userId = vm.resetAccount.userId;
		        //codiceFiscale
		        else if(vm.type==3)
		        	objRequest.codiceFiscale = vm.resetAccount.codiceFiscale;
		        
		        objRequest['g-recaptcha-response'] = vcRecaptchaService.getResponse();
		
		        Auth.resetPasswordInit(objRequest).then(function () {
		            vm.success = 'OK';
		        }).catch(function (response) {
		            if (response.status === 400 && response.data === 'email address not registered') {
		                vm.errorEmailNotExists = 'ERROR';
		            } 
		            else if (response.status === 400 && response.data === 'private mail not found') {
		                vm.errorPrivateEmailNotExists = 'ERROR';
		            }
	                else if (response.status === 400 && response.data === 'Found more than one username') {
	                    vm.errorMultipleAccounts = 'ERROR';
	                }
	                else if (response.status === 400 && response.data === 'Account inactive') {
	                    vm.errorAccountInactive = 'ERROR';
	                }
		            else {
		                vm.error = 'ERROR';
		            }
		            
		            vcRecaptchaService.reload();
		        });
        	}
            
        }
    }
})();
