(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('EnvironmentDialogController', EnvironmentDialogController);

    EnvironmentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Environment', 'Tenant'];

    function EnvironmentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Environment, Tenant) {
        var vm = this;

        vm.environment = entity;
        vm.clear = clear;
        vm.save = save;
        vm.tenants = Tenant.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.environment.id !== null) {
                Environment.update(vm.environment, onSaveSuccess, onSaveError);
            } else {
                Environment.save(vm.environment, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('unitoLandingApp:environmentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
