'use strict';

angular.module('unitoLandingApp')
	.factory('CodiceFiscaleService', ['$resource',function ($resource) {
		return {
			checkCodiceFiscale: $resource('api/public/checkCodiceFiscale', {}, {
					'check': { method: 'POST',
								params: {},
								isArray: false,
								transformResponse: function (data) {
									data = angular.fromJson(data);
									return data;
								}
                }
			}),
			checkDateCodiceFiscale: $resource('api/public/checkDateCodiceFiscale',{}, { 
				'check': {	method: 'POST',
							params: {},
							isArray: false,
							transformResponse: function (data) {
									data = angular.fromJson(data);
									return data;
							}
                }
			}),
			checkCodComuneCodiceFiscale: $resource('api/public/checkCodComuneCodiceFiscale',{}, { 
				'check': {	method: 'POST',
							params: {},
							isArray: false,
							transformResponse: function (data) {
									data = angular.fromJson(data);
									return data;
							}
                }
			}),
			checkUniqueCodiceFiscale: $resource('api/public/checkUniqueCodiceFiscale',{}, { 
				'check': {	method: 'POST',
							params: {},
							isArray: false,
							transformResponse: function (data) {
									data = angular.fromJson(data);
									return data;
							}
                }
			})
		};
	}]);
