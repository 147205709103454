'use strict';

angular.module('unitoLandingApp').controller('MailboxDetailController',
		['$scope', '$rootScope', '$stateParams', 'entity', 'UtenzeImpersonali','struttureList','AnagraficaService',function ($scope, $rootScope, $stateParams, entity, UtenzeImpersonali,struttureList,AnagraficaService) {
        $scope.mailbox = entity;
        if($scope.mailbox.userName!=null){
    		AnagraficaService.getUserType.get({userId:$scope.mailbox.userName}, function(data){
    			if(data!=null){
    				$scope.macroCategoria = data.macroCategoria;
	    			$scope.categoria = data.categoria;
	    			$scope.sottoCategoria = data.sottoCategoria;
	    			$scope.codRuolo = data.codRuolo;
	    			$scope.inquadramento = data.inquadramento;
	    			$scope.statoGenerale = data.statoGenerale;
	    			$scope.statoSpecifico = data.statoSpecifico;
    			}
    		});
    	}
        
        $scope.load = function (id) {
        	UtenzeImpersonali.get({id: id}, function(result) {
                $scope.mailbox = result;
                
            });
        };
        $rootScope.$on('unitoLandingApp:esterniUpdate', function(event, result) {
            $scope.mailbox = result;
        });
        //Strutture
    	$scope.struttureList = struttureList;
    	$scope.strutturaPrimoLivello = null;
    	$scope.strutturaSecondoLivello = null;
    	$scope.strutturaTerzoLivello = null;
        
        //Gestione strutture
    	if($scope.mailbox.struttura != null){
    		//Primo livello
    		if($scope.mailbox.struttura.livello===1){
    			$scope.strutturaPrimoLivello = $scope.mailbox.struttura;
    		}
    		//Secondo livello
    		else if($scope.mailbox.struttura.livello===2){
    			$scope.strutturaSecondoLivello = $scope.mailbox.struttura;
    			//Setto il primo
    			$scope.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
				  return obj.idAb===$scope.strutturaSecondoLivello.idAbPadre;
				})[0];
    		}
    		//Terzo livello
    		else{
    			
    			//Recupero il padre
    			var result = $scope.struttureList.filter(function( obj ) {
				  return obj.idAb===$scope.mailbox.struttura.idAbPadre;
				})[0];
    			if(result.livello===1){
    				$scope.strutturaSecondoLivello = $scope.mailbox.struttura;
    				$scope.strutturaPrimoLivello = result;
    			}
    			else{
    				$scope.strutturaTerzoLivello = $scope.mailbox.struttura;
    				$scope.strutturaSecondoLivello = result;
    				//Setto il primo
        			$scope.strutturaPrimoLivello = $scope.struttureList.filter(function( obj ) {
      				  return obj.idAb===$scope.strutturaSecondoLivello.idAbPadre;
      				})[0];
    			}
    				
    		}
    	}
    }]);
