(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .directive('onReadFile',['$parse', function ($parse) {
		    return {
		        restrict: 'A',
		        scope: false,
		        link: function(scope, element, attrs) {
		            element.bind('change', function(e) {
		                
		                var onFileReadFn = $parse(attrs.onReadFile);
		                var reader = new FileReader();
		                
		                reader.onload = function() {
		                    var fileContents = reader.result;
		                    // invoke parsed function on scope
		                    // special syntax for passing in data
		                    // to named parameters
		                    // in the parsed function
		                    // we are providing a value for the property 'contents'
		                    // in the scope we pass in to the function
		                    scope.$apply(function() {
		                        onFileReadFn(scope, {
		                            'contents' : fileContents
		                        });
		                    });
		                };
		                reader.readAsText(element[0].files[0]);
		            });
		        }
		    };
		}])
        .controller('Service_providerDialogController', Service_providerDialogController);

    Service_providerDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Service_provider', 'Distribution_list'];

    function Service_providerDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Service_provider, Distribution_list) {
        var vm = this;

        vm.service_provider = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.distribution_lists = Distribution_list.query();
        
        vm.wasApproved = vm.service_provider.is_approved;
        
        
        vm.switchOptions = {
			onText : 'yes',
			offText : 'no', 
			isActive : true,
			size : 'medium',
			animate : true,
			radioOff : true,
			handleWidth : "auto",
			labelWidth : "auto",
			inverse : true,
			onColor : "unimib",
			offColor : "default"
		};
       
        $scope.aceLoaded = function(_editor){
			// Editor part
			var _session = _editor.getSession();
			var _renderer = _editor.renderer;

			// Options
			_session.setUndoManager(new ace.UndoManager());
			_editor.setHighlightActiveLine(true);
			_editor.setHighlightSelectedWord(true);
			_editor.setShowPrintMargin(false);

		};
		
		$scope.aceOptions = {
				useWrapMode : true,
				showGutter: true,
				mode: 'xml',
				firstLineNumber: 1,
				theme: 'tomorrow',
				onLoad: $scope.aceLoaded
		};
		
        $scope.toggleActivation = function() {
          $scope.isActive = !$scope.isActive;
        }
       //--END Switch
		
		$scope.displayFileContents = function(contents) {
	        vm.service_provider.metadata = contents;
	    };
		
		

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.service_provider.id !== null) {
                Service_provider.update(vm.service_provider, onSaveSuccess, onSaveError);
            } else {
                Service_provider.save(vm.service_provider, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('unitoLandingApp:service_providerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        vm.changeWhoApproved = function(){
        	
        	if(!vm.wasApproved && vm.service_provider.is_approved){
        		vm.service_provider.whoApproved = "current-user";
        	}
        };
        
        


    }
})();
