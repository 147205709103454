(function() {
    'use strict';

    angular
        .module('unitoLandingApp')
        .controller('Distribution_listDeleteController',Distribution_listDeleteController);

    Distribution_listDeleteController.$inject = ['$uibModalInstance', 'entity', 'Distribution_list'];

    function Distribution_listDeleteController($uibModalInstance, entity, Distribution_list) {
        var vm = this;

        vm.distribution_list = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Distribution_list.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                },function(response){
                	response.statusText = "Impossibile cancellare";
                });
        }
    }
})();
